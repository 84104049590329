@use "sass:math";

// Spacers from bootstrap

$spacers: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 1rem,
  "4": 1.5rem,
  "5": 3rem,
);
$breakpoints: (
  "sm": 600px,
  "md": 900px,
  "lg": 1200px,
  "xl": 1536px,
);

@each $name, $size in $spacers {
  .p-#{$name},
  .px-#{$name},
  .pl-#{$name} {
    padding-left: $size !important;
  }
  .p-#{$name},
  .px-#{$name},
  .pr-#{$name} {
    padding-right: $size !important;
  }
  .p-#{$name},
  .py-#{$name},
  .pt-#{$name} {
    padding-top: $size !important;
  }
  .p-#{$name},
  .py-#{$name},
  .pb-#{$name} {
    padding-bottom: $size !important;
  }

  .m-#{$name},
  .mx-#{$name},
  .ml-#{$name} {
    margin-left: $size !important;
  }
  .m-#{$name},
  .mx-#{$name},
  .mr-#{$name} {
    margin-right: $size !important;
  }
  .m-#{$name},
  .my-#{$name},
  .mt-#{$name} {
    margin-top: $size !important;
  }
  .m-#{$name},
  .my-#{$name},
  .mb-#{$name} {
    margin-bottom: $size !important;
  }

  .gap-#{$name},
  .column-gap-#{$name} {
    column-gap: $size !important;
  }
  .gap-#{$name},
  .row-gap-#{$name} {
    row-gap: $size !important;
  }
}

$displays: none, block, flex;

@each $display in $displays {
  .d-#{$display} {
    display: $display !important;
  }
}

$justify-opts: (
  "start": flex-start,
  "center": center,
  "end": end,
  "between": space-between,
  "around": space-around,
);

@each $name, $value in $justify-opts {
  .justify-content-#{$name} {
    justify-content: $value !important;
  }
}

$align-opts: (
  "normal": normal,
  "stretch": stretch,
  "center": center,
  "start": start,
  "end": end,
  "flex-start": flex-start,
  "flex-end": flex-end,
  "self-start": self-start,
  "self-end": self-end,
  "anchor-center": anchor-center,
);

@each $name, $value in $align-opts {
  .align-items-#{$name} {
    align-items: $value !important;
  }
}

@each $bp, $width in $breakpoints {
  @media screen and (min-width: $width) {
    @each $name, $size in $spacers {
      .p-#{$bp}-#{$name},
      .px-#{$bp}-#{$name},
      .pl-#{$bp}-#{$name} {
        padding-left: $size !important;
      }
      .p-#{$bp}-#{$name},
      .px-#{$bp}-#{$name},
      .pr-#{$bp}-#{$name} {
        padding-right: $size !important;
      }
      .p-#{$bp}-#{$name},
      .py-#{$bp}-#{$name},
      .pt-#{$bp}-#{$name} {
        padding-top: $size !important;
      }
      .p-#{$bp}-#{$name},
      .py-#{$bp}-#{$name},
      .pb-#{$bp}-#{$name} {
        padding-bottom: $size !important;
      }

      .m-#{$bp}-#{$name},
      .mx-#{$name},
      .ml-#{$bp}-#{$name} {
        margin-left: $size !important;
      }
      .m-#{$bp}-#{$name},
      .mx-#{$bp}-#{$name},
      .mr-#{$bp}-#{$name} {
        margin-right: $size !important;
      }
      .m-#{$bp}-#{$name},
      .my-#{$bp}-#{$name},
      .mt-#{$bp}-#{$name} {
        margin-top: $size !important;
      }
      .m-#{$bp}-#{$name},
      .my-#{$bp}-#{$name},
      .mb-#{$bp}-#{$name} {
        margin-bottom: $size !important;
      }

      .gap-#{$bp}-#{$name},
      .column-gap-#{$bp}-#{$name} {
        column-gap: $size !important;
      }
      .gap-#{$bp}-#{$name},
      .row-gap-#{$bp}-#{$name} {
        row-gap: $size !important;
      }
    }

    @each $display in $displays {
      .d-#{$bp}-#{$display} {
        display: $display !important;
      }
    }

    @each $name, $value in $justify-opts {
      .justify-content-#{$bp}-#{$name} {
        justify-content: $value !important;
      }
    }

    @each $name, $value in $align-opts {
      .align-items-#{$bp}-#{$name} {
        align-items: $value !important;
      }
    }
  }
}
